export const USER_DATA = 'USER_DATA';
export const ADVISOR_DATA = 'ADVISOR_DATA';

// User
export const USER_LIABILITIES = 'USER_LIABILITIES';
export const USER_MORTGAGES = 'USER_MORTGAGES'; // can be serialized as [USER_MORTGAGES, tradeLineId]
export const USER_AUTOS = 'USER_AUTOS'; // can be serialized as [USER_AUTOS, tradeLineId]
export const USER_STUDENTS = 'USER_STUDENTS'; // can be serialized as [USER_STUDENTS, tradeLineId]
export const USER_PERSONALS = 'USER_PERSONALS'; // can be serialized as [USER_PERSONALS, tradeLineId]
export const USER_OTHER_LOANS = 'USER_OTHER_LOANS'; // can be serialized as [USER_OTHER_LOANS, tradeLineId]

// Wallet Overview
export const WALLET_OVERVIEW_DATA = 'WALLET_OVERVIEW_DATA';

// Methodfi Related
export const METHODFI_ENTITY_ID = 'METHODFI_ENTITY_ID';

// App State
export const ERROR_MESSAGE_DATA = 'ERROR_MESSAGE_DATA';
export const INFO_MESSAGE_DATA = 'INFO_MESSAGE_DATA';
export const SUCCESS_MESSAGE_DATA = 'SUCCESS_MESSAGE_DATA';
