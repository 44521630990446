import React from 'react';

function DtaQuestionnaire({ iframeUrl }) {
	return (
		<iframe
			src={iframeUrl}
			height="620"
			width="95%"
			style={{ border: 'none' }}
		/>
	);
}

export default DtaQuestionnaire;
