import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Our Hooks
import useGetProfile from 'hooks/client/useGetProfile';

//our component
import DtaQuestionnaire from 'components/Frame/DtaQuestionnaire';
import { PrimaryButton } from 'components/Button/Button';

// Our Routes
import { PROSPECTIVE_CLIENT_FINAL_ROUTE } from 'routes';

function DebtToleranceAssessment() {
	const navigate = useNavigate();

	const { data: profileData } = useGetProfile((userData) => userData, true);

	const iframeUrl = `https://interview.getreveal.ai/debt-risk-assessment?iframe=true&colorMode=light&rtid=${profileData.hash}`; //passing hashed userId for security
	return (
		<>
			<Grid
				container
				item
				xs={12}
				sx={{
					paddingTop: { xs: 0, md: 4 },
					paddingLeft: { xs: 2, md: 10 },
					flexDirection: 'column',
					gap: 4,
					marginBottom: 4
				}}
			>
				<Typography variant="h1Gl">
					Debt Tolerance Assessment
				</Typography>
				<Typography variant="body1">
					The debt tolerance score will allow your financial advisor
					to understand your preferences and tolerance for debt to
					know the best way to support you in your growing net worth.
				</Typography>
			</Grid>
			<Grid
				container
				item
				xs={12}
				sx={{
					paddingTop: { xs: 0, md: 4 },
					paddingLeft: { xs: 2, md: 10 },
					flexDirection: 'column',
					gap: 4,
					marginBottom: 4
				}}
			>
				<DtaQuestionnaire iframeUrl={iframeUrl} />
				<Grid
					container
					item
					xs={12}
					md={2}
					xl={1}
					sx={{
						justifyContent: 'center'
					}}
				>
					<PrimaryButton
						sx={{
							marginBottom: 2,
							width: '90%',
							padding: {
								xs: 2,
								md: 2
							}
						}}
						onClick={() => navigate(PROSPECTIVE_CLIENT_FINAL_ROUTE)}
					>
						Submit
					</PrimaryButton>
				</Grid>
			</Grid>
		</>
	);
}

export default DebtToleranceAssessment;
