export const API_URL = process.env.REACT_APP_API_BASE_URL;
export const APP_VERSION = process.env.REACT_APP_VERSION;

// Special
export const maskAsAnotherUser = `${API_URL}/v1/info/super`;
export const createClientEndpoint = `${API_URL}/fa/add-client`;

// Methodfi
export const getMethodfiToken = `${API_URL}/mfi-connect`;
export const getMethodfiLiabilities = `${API_URL}/mfi-liabilities/`;

// User
export const getUserLiabilitiesUrl = `${API_URL}/v1/user/liability/`;
export const userProfile = `${API_URL}/user/profile`;
export const userOnboardingStatus = `${API_URL}/v1/user/onboarding-status/`;
export const userLogin = `${API_URL}/user/login`;

// Prospective Client
export const updateProspectiveClientEmailEndpoint = `${API_URL}/prospect-client/add-email`;
export const getEmailFromHashEndpoint = `${API_URL}/prospect-client/`;

// Client
export const userIncomeEndpoint = `${API_URL}/user/income`;
export const userAssetsEndpoint = `${API_URL}/user/asset`;

// Wallet
//
export const walletOverviewUrl = `${API_URL}/wallet/overview`;
